import { createApp } from 'vue'
import app from './App.vue'
import router from "./router";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_HOSTNAME

import PrimeVue from 'primevue/config';
import VueCookies from "vue-cookies"

import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import '/node_modules/primeflex/primeflex.css'

//add styles
import './assets/css/main.css'

let ap = createApp(app)


ap
.use(router)
.use(PrimeVue)
.use(VueCookies)
.component('Button', Button)
.component('ProgressSpinner', ProgressSpinner)
ap.mount('#app')