<template>
    <div class="ad">
        <form method="post" action="https://wallet.advcash.com/sci/" id="adv_form">
            <input type="hidden" name="ac_account_email" value="info@prodaway.org" />
            <input type="hidden" name="ac_sci_name" value="Customer Solutions LTD" />
            <input type="hidden" name="ac_amount" :value="amount" />
            <input type="hidden" name="ac_currency" :value="toUpper(currency)" />
            <input type="hidden" name="ac_order_id" :value="transfer_id+Date.now()" />
            <input type="hidden" name="ac_sign" value="c31a35deaf57ee9677586ce6e225dadd9e1ec27aef6843fd01aaa8e8f0f309bb" />
            <!-- Optional Fields -->
            <input type="hidden" name="ac_success_url" value="https://prodaway.org/successtransfer" />
            <input type="hidden" name="ac_success_url_method" value="GET" />
            <input type="hidden" name="ac_fail_url" value="https://prodaway.org/failedtransfer" />
            <input type="hidden" name="ac_fail_url_method" value="GET" />
            <input type="hidden" name="ac_status_url" value="https://prodaway.org/" />
            <input type="hidden" name="ac_status_url_method" value="GET" />
            <input type="hidden" name="ac_comments" :value="`Payment order(${transfer_id})`" />
            <!-- <input type="submit" /> -->
    </form>

        <Button label="Pay by card" @click="pay()" />
        
    </div>
</template>


<script>
import Connect from '@/assets/js/Connect'
export default {
    data(){
        return{
            ADV_SUCCESS_URL: null,
            ADV_CANCEL_URL: null,
            ADV_STATUS_URL: null,
        }
    },  
    props: ['amount','currency','transfer_id', 'pay_now'],
    methods: {
        pay(){
            document.getElementById('adv_form').submit();
        },
        toUpper(str){
            return str.toUpperCase()
        }
    },  
    async mounted(){
        this.connect = new Connect()
        this.ADV_SUCCESS_URL = this.connect.SUCCESS_URL
        this.ADV_CANCEL_URL = this.connect.CANCEL_URL
        this.ADV_STATUS_URL = this.connect.STATUS_URL

        if(this.$props.pay_now){
            this.pay()
        }
    }
}
</script>