<template>

<div class="flex min-h-screen min-w-screen">
      <ProgressSpinner class="flex align-items-center justify-content-center min-h-screen"/>
    </div>

</template>
  
  
<script>
import Connect from '@/assets/js/Connect'

export default {
    data() {
        return {
            token: null,
            PayerID: null,
        }
    },
    methods: {

    },
    async beforeCreate() {
        this.connect = new Connect()
    },
    async mounted() {
        this.connect = new Connect()
        console.log(this.$route.query)
        if (this.$route.query.token != '') this.token = this.$route.query.token
        if (this.$route.query.PayerID != '') this.PayerID = this.$route.query.PayerID
        //paypal
        if (this.token) {
            let exec = await this.connect.exec_payment(this.token).then(res => res)
            console.log('exec', exec)
            if(exec.result){
                window.location.href = this.connect.SUCCESS_URL
            }else{
                window.location.href = this.connect.CANCEL_URL
            }
        }
    },
}
</script>