<template>
  <div class="flex min-h-screen min-w-screen">
    <ProgressSpinner class="flex align-items-center justify-content-center min-h-screen"/>
  </div>
  <div v-if="isAdvPayment" class="hidden" style="display: none">
      <div>
        <PayFormAdv
          :amount="amount"
          :currency="currency"
          :transfer_id="order_id"
          :pay_now="check_pay"
        />
      </div>
  </div>
</template>

<script>
import Connect from '@/assets/js/Connect'
import PayFormAdv from '@/components/PayFormAdv'
import PayFormPaypal from '@/components/PayFormPaypal.vue';
export default {
  data() {
    return {
      amount: null,
      currency: null,
      payment_system: null,
      order_id: null, //external
      transfer_id: null,
      link: null,
      check_pay: false,
      isAdvPayment: false,
    }
  },
  components: {
    PayFormAdv,
    PayFormPaypal,
  },
  async mounted() {
      this.check_pay = true
      this.connect = new Connect()
      this.amount = this.$route.query.amount;
      this.isTest = this.$route.query.test === '1';
      this.currency = this.$route.query.currency?.toUpperCase();
      this.payment_system = this.$route.query.type;
      this.isAdvPayment = this.$route.query.type === 'adv';
      this.order_id = this.$route.query.order_id;
      const isValid = this.amount && this.currency && this.payment_system && this.order_id;

      if (this.isAdvPayment || !isValid ) {
        return
      }

      const order = await this.connect.create_payment({
        amount: this.amount,
        currency: this.currency,
        payment_system: this.payment_system,
        order_id: this.order_id,
        is_test: this.isTest
      })
      this.link = order.result && order.data;
      window.location.href = this.link || this.connect.CANCEL_URL;
  },
}
</script>

 

