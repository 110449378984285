<template>
  <div v-if="!paid" id="paypal-button-container"></div>
  <div v-else id="confirmation">Order complete!</div>
</template>
  
<script>
import { loadScript } from '@paypal/paypal-js';

export default {
  name: 'Checkout-Payment',
  props: ['amount', 'currency', 'transfer_id', 'pay_now'],
  data() {
    return {
      paid: false,
    }
  },
  methods: {
    createOrder: function (data, actions) {
      console.log('Creating order...');
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: Number(this.amount),
              currency_code: this.currency,
            },
            reference_id: this.transfer_id,
          },
        ],
      });
    },
    onApprove: function (data, actions) {
      console.log('Order approved...');
      return actions.order.capture().then(() => {
        this.paid = true;
        console.log('Order complete!');
      });
    },
  },
  beforeCreate: function () {
    loadScript({ 'client-id': CLIENT_ID }).then((paypal) => {
      paypal
        .Buttons({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
        })
        .render('#paypal-button-container');
    })
  },
  mounted() {
    console.log(this.$actions)
    if (this.$props.pay_now) {
      // console.log(this.$props)
      // this.createOrder(true)

      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: Number(this.amount),
              currency_code: this.currency,
            },
            reference_id: this.transfer_id,
          },
        ],
      });
      
    }
  }
}
const CLIENT_ID = 'AeV8CU_C4bqsFzE9yMISw43tJbxFAxA0zhaOG3b-o5HBbOQoyB1e8b6oPOX17m4wGSIzQWPjywYqWjt7';
  //ASOq8aTMqef607XNVrdWH1K8KR72cwHbsQFKRVKVx6Wygup98gJns1LctNle2Ce36X_-UQ1BUfPMJrk
</script>
  
<style>
#paypal-button-container {
  margin: 30px 0;
}

#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>